<template>
    <div>
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
                <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Master Tindakan Lab</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-button variant="primary" @click="pindahInput()"
                    ><CIcon name="cil-plus" /> Tambah Data</b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <hr />
                </b-col>
              </b-row>

              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Per Halaman"
                    label-for="per-page-select"
                    label-cols-md="6"
                    label-align-md="left"
                    label-size="md"
                    style="background-color: "
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="md"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
  
                <b-col md="6" offset-md="3">
                  <b-form-group
                    label="Cari"
                    label-for="filter-input"
                    label-cols-md="3"
                    label-align-md="right"
                    label-size="md"
                  >
                    <b-input-group size="md">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Ketik disini untuk mencari ..."
                      ></b-form-input>
  
                      <b-input-group-append>
                        <b-button
                          :disabled="!filter"
                          @click="filter = ''"
                          variant="danger"
                          >Hapus</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
  
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    responsive
                    show-empty
                    small
                    @filtered="onFiltered"
                    bordered
                    striped
                    hover
                    :busy="tableBusy"
                  >
                    <template #cell(actions)="item">
                      <b-button
                        variant="warning"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Edit Data'"
                        @click="editItem(item.item)"
                        ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                      >
  
                      <b-button
                        variant="danger"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        v-b-modal.modal-delete-tindakan
                        @click="datanya = item.item"
                        ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                      >
                    </template>
                    <template #cell(bulk_sub_tindakan_lab)="item">
                        <b-row>
                            <b-col>
                                <ul>
                                    <li v-for="(itemnya, index) in item.item.bulk_sub_tindakan_lab" :key="index">{{ itemnya.nama_sub_tindakan_lab }}</li>
                                </ul>
                            </b-col>
                        </b-row>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
  
              <b-row>
                <b-col md="5" offset-md="7">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <ModalDeleteTindakan
        :data_delete="datanya"
        @alertFromChild="triggerAlert($event)"
        @tutupModal="getDatas()"
      />
    </div>
  </template>
  
  <script>
  import ModalDeleteTindakan from "./modal_delete_tindakan.vue";
  export default {
    components: {
        ModalDeleteTindakan
    },
    name: "tindakan_lab",
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        is_data: "",
        datanya: "",
        fields: [
        {
            key: "no",
            label: "No",
            class: "text-left",
          },

          {
            key: "nama_tindakan_laboratorium",
            label: "Nama Tindakan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "bulk_sub_tindakan_lab",
            label: "Sub Tindakan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
  
          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        // filterOn: [],
        tableBusy: false,
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      filterOn() {
        // console.log(this.fields.map(el => el.key))
        return this.fields.map(el => el.key)
      },
    },
    activated(){
        this.totalRows = this.items.length;
      this.getDatas();
 
    },
    async mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
      this.getDatas();
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getDatas() {
        const vm = this
        try {
          vm.tableBusy = true;
          let id = localStorage.getItem('idp')
          let res1 = await vm.$axios.post("/tindakan_lab/list", {ms_puskesmas_id: id});
          console.log(res1, 'ini ressnya');
          vm.items = res1.data.data.map((x, i) => {
            return {
              ...x,
              no: i + 1,
            }
          })
          vm.totalRows = vm.items.length;
          
        } catch (err) {
          if (err.message) {
            vm.$store.commit("set_alert", {variant: "danger",
              msg: (err.message).toUpperCase(),
              showing: true,
            });
          } else {
            vm.$store.commit("set_alert", {variant: "danger",
              msg: (err.message).toUpperCase(),
              showing: true,
            });
          }
        } finally {
          this.tableBusy = false;
        }
      },
      pindahInput(){
        let vm = this
        vm.$router.push({
            path: "/laboratorium/input_tindakan_lab",
            // query: { id_ba : data.item.id },
        });
      },
      editItem(x){
        console.log(x, 'ini item');
        this.$store.commit('set_data_tindakan_lab', x)
        this.$router.push({
            path: "/laboratorium/edit_tindakan_lab",
            query: { id : x.tindakan_laboratorium_id },
        });
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
        vm.getDatas()
      },
    },
  };
  </script>
  